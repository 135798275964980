
/* 815D2A,AE7D54,A49893,9C3913,9C3913 */ 

/*   #F1E9E4; */
:root {
  --primary: #E7B650;
  --primary2: #E8BB60;
  --primary3: #EAC170;
  --primary4: #ECC780;
  --primary5: #EFCF95;
  --primary6: #F2D6AA;
  --primary7: #F6DEBF;
  --primary8: #F9E6D4;
  --secondary: #9c8fd8;
  --accent: #000000;
  --background: linear-gradient(#E7B650, #E8BB60 2%, #EAC170 3%, #ECC780 5%, #EFCF95 8%, #F2D6AA 10%, #F6DEBF 15%, #F9E6D4 25%, #FFFFFF 60%);
  --background1: #ffffff;
  --background2: linear-gradient(#FFFFFF, #FBF0DC 33%, #F8E2C3 66%, #EFCF95);

  /* linear-gradient(#FFFFFF 40%, #F9E6D4 75%, #F6DEBF 85%, #F2D6AA 90%, #EFCF95 92%, #ECC780 95%, #EAC170 97%, #E8BB60 98%, #E7B650); */
  --button: #f5c542;
  --button-hover: #9c7814;
  /* --background2: #E7B650; */
  --banner: #F9E6D490; 
  /* 49; */
  --text: #000000; 
  /* #310900; *791701; */
}

/* linear-gradient(#fcfcfc, #b0b4b4 30%, #a49893 70%, #7b664a); */
/* BEst: background: radial-gradient(circle at 20% 30%, #fcfcfc, #b0b4b4 70%, #815d2a);
/* radial-gradient(circle at 80% 80%, #b0b4b4, #a49893 70%, #815d2a); */
/* radial-gradient(circle at 80% 80%, #b0b4b4, #a49893 70%, #815d2a); */
/* :root {
    --primary: #4a47a3;
    --secondary: #9c8fd8;
    --accent: #f5c542;
    --background: #ffffff;
    --text: #333333;
  } */
  
  body {
    background-color: var(--background);
    color: var(--text);
    font-family: 'Inter', sans-serif;
  }
  